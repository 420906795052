:root {
  --black: black;
  --white: white;
}

@font-face {
  font-family: Roman;
  src: url('./fonts/Times-New-Roman.otf');
}

/* Basics */
.top-15{
  margin-top: 15px !important;
}

.top-50{
  margin-top: 50px !important;
}

.next{
display: flex !important;
align-items: center;
justify-content: space-between;
}

.color-primary{
color: var(--primary-color);
}

.roman{
font-family: Roman !important;
}

.bold{
  font-weight: bold;
}

.app-container{
  padding : 25px 50px;
}

.not-found{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

a{
  text-decoration: none !important;
  color: var(--black) !important;

}

/* Navbar */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-title{
  font-size: 1.5rem;
}

.nav-menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1;
}

.nav-popup {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: #fff;
  color: #333;
  transition: right 0.3s ease;
  border-left: 1px solid #333;
  z-index: 0;
}

.nav-popup.open {
  right: 0;
}

.nav-popup ul {
  list-style: none;
  padding: 2rem;
}

.nav-popup ul li {
  margin: 1rem 0;
}

.nav-popup ul li a {
  text-decoration: none;
  color: #333;
}

/* Footer */	  
.footer{
  height: 5vh;

  position: absolute;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  bottom: 0;
  text-align: center;
}

/* Homepage */

.homepage{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
  height: 80vh;
}

.homepage-img{
  height: 100%;
  object-fit: cover;

}

/* Detail Page */
.detail{
  display: flex;
  margin-top: 5vh;
  width: 100%;
  height: 70vh;

}

.detail-info{
  height: 100%;
  width: 70vw !important;
}

.detail-title{
  font-size: 2rem;
}
.detail-title-small{
  font-size: 1.5rem;
}
.detail-date{
  font-size: 1.2rem;
  font-style: italic;
  color: #9a9a9a;
  font-weight: thin;
}

.detail-carousel{
  margin-left: 75px;
  height: 100%;
  overflow-x: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

}

.detail-carousel::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.detail-images{
  display: flex;
  height: 100%;
}
.detail-images img{
  height: 100%;
  margin-right: 40px;
}

.detail-arrow-right{
  cursor: pointer;
  position: absolute;
  bottom: 50px;
  right: 50px;
}

.detail-arrow-left{
  cursor: pointer;
  position: absolute;
  bottom: 50px;
  left: 20.5vw;
}
/* styles.css */
.works-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 100px;
  margin-top: 5vh;
  width: 90vw;
}

.works-grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
}
.works-grid-item:nth-child(2) {
  margin-top: 0px !important;
}

.works-grid-item:nth-child(even) {
  margin-top: 350px;
  float: right;

}

.white{
 width: 10px !important;
  height: 10px !important;
  margin-bottom: 0px !important;
}

.works-grid-item:nth-child(odd) {
  margin-bottom: 250px;
}

.works-grid-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* styles.css */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100vh;
  background-color: rgba(255, 255, 255, 1); /* White background */
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  padding: 20px;
}

.modal-content{
  border-radius: 0 !important;
  border: 0 !important;
  height: 80vh;
}

.modal-content img {
  width: 100%;
  max-height: 95vh;
  object-fit: contain;
  
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #aaa;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-content-works{
  margin: auto;
  display: flex;
  width: 80%;
  max-width: 1000px;
  padding: 20px;

}
.modal-text{
  width: 200px !important;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;

}


.modal-content-works img {
  width: 100%;
  max-height: 95vh;
  object-fit: contain;
  
}


.detail-icons{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.detail-icons a{
  margin-right: 5px;
}

.detail-icons a svg{
  width: 20px;
  height: 20px;
}


/* Mobile */
@media (max-width: 768px) {
  .app-container{
    padding : 10px 10px;
    height: 100vh;
  }

  .navbar{
    margin-top: 5vh;
    padding: 0px 20px !important;
  }

  .homepage{
    margin-top: 5vh;
    
    height: max-content;
  }
  .homepage-img{
    height: 50vh;
    padding : 0px 20px;
  }

  .detail{
    display: flex;
    flex-direction: column;
  }
  .detail-info{
    width: 100% !important;
    height: auto;
    padding : 0px 20px;
  }
  .detail-title{
    font-size: 1.5rem;
  }
  .detail-title-small{
    font-size: 1.2rem;
  }
 
  .detail-date{
    font-size: 1rem;
  }
  .detail-carousel{
    margin-left: 0px;
    margin-top: 20px;
    padding : 0px 20px;
  }
  .works-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    margin-top: 5vh;
    padding-bottom: 2vh;
    width: 100%;
  }

  .white{
    display: none;
  }

  .works-grid-item:nth-child(even) {
    margin-top: 0px;

  
  }

  .bigimages{
    height: auto !important;
    width: 100% !important;
  }

  .bigcarousel{
    margin-top: 50px;
    height: max-content !important;
  }
  
  .works-grid-item:nth-child(odd) {
    margin-bottom: 0px;
  }
  
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100vw; /* Full width */
    height: 100vh;
    background-color: rgba(255, 255, 255, 1); /* White background */
  }
  .modal-content {
    margin: auto;
    display: block;
    width: 80%;
    padding: 20px;
    margin-top: 20vh;
  }

  .modal-content-works{
    margin-top: 20vh !important;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 20px;
  
  }
  .modal-text{
    width: 200px !important;
    padding:10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
  
  }
}
